<script>
	export let css = 'h-6 w-6';
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	class={css}
	fill="none"
	viewBox="0 0 24 24"
	stroke="currentColor"
	id="hamburger-menu-svg"
>
	<path
		stroke-linecap="round"
		stroke-linejoin="round"
		class="stroke-2 group-focus:stroke-3 group-hover:stroke-3"
		d="M4 6h16M4 12h16M4 18h16"
		id="hamburger-menu-path"
	/>
</svg>
