<script>
	import { _ } from 'svelte-i18n';
	import { push as navigateTo } from 'svelte-spa-router';
	import { logout, deleteCookies, getProfile } from '$utils/api.js';
	import { data, showSpinner, isRemoteAuthClient } from '$src/stores.js';
	import * as animateScroll from 'svelte-scrollto';
	import { slide, fade } from 'svelte/transition';
	import CloseIcon from '$lib/icon/CloseIcon.svelte';
	import MenuIcon from './icon/MenuIcon.svelte';
	import ExternalLinkIcon from './icon/ExternalLinkIcon.svelte';

	export let profile = false;
	export let editMode = false;
	export let overrideTitle = '';
	export let showMenu = true;

	let menuOpen = false;

	async function logoutUser() {
		try {
			$showSpinner = true;
			await logout({ clearSession: true });
			$data = {};
		} catch (err) {
			console.error(err);
		} finally {
			$showSpinner = false;
			navigateTo('/login');
		}
	}

	$: menuLinks = [
		{
			text: $_('Pictures'),
			id: '#pictures'
		},
		{
			text: $_('Providers'),
			id: '#providers'
		},
		{
			text: $_('Devices'),
			id: '#devices'
		},
		{
			text: $_('Applications'),
			id: '#applications'
		}
	];

	let forgetMeAjax = false;
	async function forgetMeHandler() {
		try {
			forgetMeAjax = true;
			$showSpinner = true;
			await deleteCookies();
			$data = await getProfile();
		} catch (err) {
			console.error(err);
		} finally {
			$showSpinner = false;
			forgetMeAjax = false;
			navigateTo('/login');
		}
	}
</script>

<header
	data-test="hello-title-bar"
	class="flex-shrink-0 bg-charcoal w-full text-white dark:text-[#D4D4D4] h-12"
	style="z-index: 60"
>
	<div
		class="h-full px-3 container relative mx-auto flex items-center justify-between {!$data.isPersonalLoggedIn
			? 'max-w-md'
			: 'max-w-2xl'}"
	>
		<!-- max-w-2xl -->
		<div class="w-32 inline-flex items-center justify-start">
			{#if showMenu}
				<button
					data-test="menu"
					aria-label="Menu"
					on:click={() => (menuOpen = !menuOpen)}
					class="group inline-flex items-center"
				>
					{#if !menuOpen}
						<MenuIcon css="h-6 w-6" />
					{:else}
						<CloseIcon css="h-6 w-6" />
					{/if}
				</button>
			{/if}
		</div>

		{#if menuOpen}
			<div class="absolute top-12 left-0 px-3 container mx-auto">
				<div
					data-test="menu-dropdown"
					class="fixed z-50 bg-charcoal px-4 py-2 -ml-3 md:-ml-0 text-white dark:text-[#D4D4D4]"
					transition:slide|local
				>
					<ul class="space-y-3 flex flex-col">
						{#if window.isWalletApp || window.isWalletInviteApp}
							{#if $data?.isPersonalLoggedIn && profile}
								{#each menuLinks as { text, id } (id)}
									<li class="inline-block">
										<button
											class="focus:underline hover:underline"
											on:click={() => {
												animateScroll.scrollTo({ container: 'main', element: id, offset: -20 });
												menuOpen = false;
											}}
										>
											{text}
										</button>
									</li>
								{/each}
							{/if}

							<li class="inline-block">
								<a
									href="/mastodon"
									target="_blank"
									class="focus:underline hover:underline inline-flex items-center"
								>
									<img
										src="https://cdn.hello.coop/images/mastodon.svg"
										alt="mastodon"
										class="h-5"
									/>
									<span class="ml-1.5">Mastodon Builder</span>
									<ExternalLinkIcon css="h-4 w-4 ml-2" />
								</a>
							</li>
						{:else if window.isWalletMastodonApp}
							<li class="inline-block w-full">
								<a
									data-test="open-wallet-link"
									class="focus:underline hover:underline inline-flex items-center justify-between w-full"
									href={window.location.origin}
									target="_blank"
								>
									<span>{$_('Open your wallet')}</span>
									<ExternalLinkIcon css="h-4 w-4 ml-2" />
								</a>
							</li>
						{/if}
					</ul>
				</div>
			</div>
			<!-- svelte-ignore a11y-click-events-have-key-events -->
			<span
				on:click={() => (menuOpen = false)}
				class="fixed w-screen h-screen left-0 bottom-0 top-12 bg-[#151515]/80"
				transition:fade
			/>
		{/if}

		{#if overrideTitle}
			<span class="block w-64 text-center truncate text-xl font-semibold">
				{overrideTitle}
			</span>
		{:else if profile}
			<span class="block w-64 text-center truncate text-xl font-semibold">
				{#if editMode}
					{$_('Edit Hellō Wallet')}
				{:else}
					{$_('Hellō Wallet')}
				{/if}
			</span>
		{:else}
			<a
				href="https://www.hello.coop"
				target="_blank"
				class="hover:underline focus:underline text-xl font-semibold">Hellō</a
			>
		{/if}

		<div class="w-32 inline-flex items-center justify-end">
			<span class="text-right">
				{#if $data?.isPersonalLoggedIn && !$isRemoteAuthClient}
					<button
						data-test="logout-btn"
						on:click={logoutUser}
						class="focus:underline hover:underline font-semibold text-sm"
					>
						{$_('Log out')}
					</button>
				{:else if $data?.isManagedLoggedIn || $data?.preferred?.length || $data?.managed?.length}
					<button
						data-test="forget-me-btn"
						on:click={forgetMeHandler}
						disabled={forgetMeAjax}
						class="focus:underline hover:underline font-semibold text-sm"
					>
						{forgetMeAjax ? 'Forgetting...' : 'Forget me'}
					</button>
				{/if}
			</span>
		</div>
	</div>
</header>
